import React from 'react';
import Sidebar from './Sidebar';
import UserContext from '../Helpers/UserContext';
import Session from '../Helpers/Session';
import userPhoto from "../assets/images/photo.png";
import { useMutation } from '@apollo/client';
import { LOGOUT } from '../GraphQL/Mutations';
import { useNavigate } from 'react-router-dom';
import Confirm from './Confirm';
import Redirect from '../Helpers/Redirect';
import { Loading } from './Loading';

export const TopBar = ({ fullname, photo, role = "Admin" }: { fullname: string, photo: string, role?: string }) => {
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const navigate = useNavigate();
    const [showConfirm, setShowConfirm] = React.useState(false);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const [logout, { loading }] = useMutation(LOGOUT, {
        onCompleted: () => {
            Session.clearAllCookies();
            Session.removeAll();
            Redirect("/login");
        }
    });

    if (loading) <Loading fullPage={true} message='Logging out...' />

    return (
        <>
            <div className='w-100 border-bottom'>
                <div className="d-flex justify-content-end align-items-center bg-light py-3 pe-5">
                    <div className="me-4">
                        <i className="bi bi-bell fs-4 text-muted" />
                    </div>
                    <div className="d-flex align-items-center position-relative">
                        <div
                            className='rounded-circle d-flex align-items-center justify-content-center'
                            style={{ width: '40px', height: '40px' }}
                        >
                            <img src={photo} alt={fullname} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                        </div>
                        <div className="d-flex flex-column justify-content-start ps-2">
                            <span className="text-sm fw-bold ms-1">{fullname}</span>
                            <span className="text-sm fw-normal ms-1">{role}</span>
                        </div>
                        <i className={"bi fs-5 text-muted ps-2 dropdown-toggle " + (isDropdownOpen ? "bi-chevron-right" : "bi-chevron-down")} onClick={toggleDropdown} role="button" />

                        {isDropdownOpen && (
                            <div className="dropdown-menu show position-absolute top-100 end-0 mt-2">
                                <div className="dropdown-item"
                                    onClick={() => navigate("/update-profile")}
                                >
                                    <i className="bi bi-person-circle me-2"></i>
                                    Update Profile
                                </div>
                                <div className="dropdown-item" onClick={() => setShowConfirm(true)}>
                                    <i className="bi bi-box-arrow-right me-2"></i>
                                    Logout
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {
                showConfirm && (
                    <Confirm message={"Are you sure you want to logout?"} topic='Logout' onCancel={() => setShowConfirm(false)} cancelText='No' takeActionText='Yes' key='logout' onConfirm={() => logout()} />
                )
            }

        </>

    );
};

const Layout = ({ children }: { children: React.ReactNode }) => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    return (
        <div className="d-flex">
            <Sidebar />
            <div className="w-100">
                <TopBar fullname={user.fullname} photo={user.photo ? user.photo : userPhoto} role={user.role_name} />
                {children}
            </div>
        </div>
    );
};

export default Layout;
