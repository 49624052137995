import React from 'react';
import UserContext from './Helpers/UserContext';
import { useQuery } from '@apollo/client';
import { isLoggedIn } from './Helpers/IsLoggedIn';
import { CURRENT_ADMIN, GET_CONFIG } from './GraphQL/Queries';
import Session from './Helpers/Session';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Loading } from './Components/Loading';
import { CurrentUser } from './types/CurrentUser';
import Layout from './Components/Layout';

const Register = React.lazy(() => import('./Pages/Auth/Register'));
const Login = React.lazy(() => import('./Pages/Auth/Login'));
const Logout = React.lazy(() => import('./Pages/Auth/Logout'));

const Dashboard = React.lazy(() => import('./Pages/MenuPages/Dashboard'));
const Merchant = React.lazy(() => import('./Pages/MenuPages/Merchant/Merchant'));
const MerchantNotification = React.lazy(() => import('./Pages/MenuPages/Merchant/MerchantNotification'));
const ManageMerchant = React.lazy(() => import('./Pages/MenuPages/Merchant/ManageMerchant'));
const Courier = React.lazy(() => import('./Pages/MenuPages/Courier/Courier'));
const CourierNotification = React.lazy(() => import('./Pages/MenuPages/Courier/CourierNotification'));
const ExportCourier = React.lazy(() => import('./Pages/MenuPages/Courier/ExportCourier'));
const ManageCourier = React.lazy(() => import('./Pages/MenuPages/Courier/ManageCourier'));
const Role = React.lazy(() => import('./Pages/MenuPages/Role/Role'))
const AddRole = React.lazy(() => import('./Pages/MenuPages/Role/AddRole'))
const EditRole = React.lazy(() => import('./Pages/MenuPages/Role/EditRole'))
const AdminAccounts = React.lazy(() => import('./Pages/MenuPages/AdminAccounts/AdminAccounts'));
const AddAdminAccount = React.lazy(() => import('./Pages/MenuPages/AdminAccounts/AddAdminAccount'));
const EditAdminAccount = React.lazy(() => import('./Pages/MenuPages/AdminAccounts/EditAdminAccount'));
const EmailMessages = React.lazy(() => import('./Pages/MenuPages/Email/EmailMessages'));
const ComposeMail = React.lazy(() => import('./Pages/MenuPages/Email/ComposeMail'));
const AuditTrails = React.lazy(() => import('./Pages/MenuPages/AuditTrails/AuditTrails'));
const Transaction = React.lazy(() => import('./Pages/MenuPages/Transaction/Transaction'));
const AddTransaction = React.lazy(() => import('./Pages/MenuPages/Transaction/AddTransaction'));
const Order = React.lazy(() => import('./Pages/MenuPages/Order/Order'));
const EditOrder = React.lazy(() => import('./Pages/MenuPages/Order/EditOrder'));
const Report = React.lazy(() => import('./Pages/MenuPages/Report/Report'));
const UpdateProfile = React.lazy(() => import('./Pages/Auth/UpdateProfile'));
const App: React.FC = () => {
    const [user, setUser] = React.useState<CurrentUser | null>(null);
    const [config, setConfig] = React.useState('{}');
    const loggedIn = isLoggedIn();

    useQuery(GET_CONFIG, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            setConfig(data.getConfig);
            Session.setCookie('config', JSON.stringify(data.getConfig));
        }
    });

    useQuery(CURRENT_ADMIN, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setUser(data.currentAdmin);
            Session.setCookie('user', JSON.stringify(data.currentAdmin));
            Session.set("user", data.currentAdmin);
        }
    });

    if (!loggedIn) {
        return (
            <Router>
                <Routes>
                    <Route path={'/register'} element={<React.Suspense fallback={<Loading />}> <Register /> </React.Suspense>} />
                    <Route path="*" element={<Login />} />
                </Routes>
            </Router>
        );
    } else {
        return (
            <UserContext.Provider value={{ user, setUser, config, setConfig }}>
                <Router>
                    <Routes>
                        <Route path={'/'} element={
                            <Layout>
                                <React.Suspense fallback={<Loading />}>
                                    <Dashboard />
                                </React.Suspense>
                            </Layout>
                        } />
                        <Route path={'/dashboard'} element={<Navigate to="/" />} />
                        <Route path={'/update-profile'} element={
                            <React.Suspense fallback={<Loading />}>
                                <UpdateProfile />
                            </React.Suspense>
                        } />
                        <Route path={'/merchant'} element={
                            <React.Suspense fallback={<Loading />}>
                                <Merchant />
                            </React.Suspense>
                        } />
                        <Route path={'/merchant/notification'} element={
                            <React.Suspense fallback={<Loading />}>
                                <MerchantNotification />
                            </React.Suspense>
                        } />
                        <Route path={'/merchant/manage'} element={
                            <React.Suspense fallback={<Loading />}>
                                <ManageMerchant />
                            </React.Suspense>
                        } />
                        <Route path={'/courier'} element={
                            <Layout>
                                <React.Suspense fallback={<Loading />}>
                                    <Courier />
                                </React.Suspense>
                            </Layout>
                        } />
                        <Route path={'/courier/export'} element={
                            <React.Suspense fallback={<Loading />}>
                                <ExportCourier />
                            </React.Suspense>
                        } />
                        <Route path={'/courier/notification'} element={
                            <React.Suspense fallback={<Loading />}>
                                <CourierNotification />
                            </React.Suspense>
                        } />
                        <Route path={'/courier/manage'} element={
                            <React.Suspense fallback={<Loading />}>
                                <ManageCourier />
                            </React.Suspense>
                        } />
                        <Route path={'/roles'} element={
                            <React.Suspense fallback={<Loading />}>
                                <Role />
                            </React.Suspense>
                        } />
                        <Route path={'/roles/add'} element={
                            <React.Suspense fallback={<Loading />}>
                                <AddRole />
                            </React.Suspense>
                        } />
                        <Route path={'/roles/edit'} element={
                            <React.Suspense fallback={<Loading />}>
                                <EditRole />
                            </React.Suspense>
                        } />
                        <Route path={'/admin'} element={
                            <React.Suspense fallback={<Loading />}>
                                <AdminAccounts />
                            </React.Suspense>
                        } />
                        <Route path={'/admin/add'} element={
                            <React.Suspense fallback={<Loading />}>
                                <AddAdminAccount />
                            </React.Suspense>
                        } />
                        <Route path={'/admin/edit'} element={
                            <React.Suspense fallback={<Loading />}>
                                <EditAdminAccount />
                            </React.Suspense>
                        } />
                        <Route path={'/email'} element={
                            <React.Suspense fallback={<Loading />}>
                                <EmailMessages />
                            </React.Suspense>
                        } />
                        <Route path={'/email/compose'} element={
                            <Layout>
                                <React.Suspense fallback={<Loading />}>
                                    <ComposeMail />
                                </React.Suspense>
                            </Layout>
                        } />
                        <Route path={'/audit-trails'} element={
                            <Layout>
                                <React.Suspense fallback={<Loading />}>
                                    <AuditTrails />
                                </React.Suspense>
                            </Layout>
                        } />
                        <Route path={'/transaction'} element={
                            <React.Suspense fallback={<Loading />}>
                                <Transaction />
                            </React.Suspense>
                        } />
                        <Route path={'/transaction/add'} element={
                            <React.Suspense fallback={<Loading />}>
                                <AddTransaction />
                            </React.Suspense>
                        } />
                        <Route path={'/order'} element={
                            <React.Suspense fallback={<Loading />}>
                                <Order />
                            </React.Suspense>
                        } />
                        <Route path={'/order/edit'} element={
                            <Layout>
                                <React.Suspense fallback={<Loading />}>
                                    <EditOrder />
                                </React.Suspense>
                            </Layout>
                        } />
                        <Route path={'/reports'} element={
                            <Layout>
                                <React.Suspense fallback={<Loading />}>
                                    <Report />
                                </React.Suspense>
                            </Layout>
                        } />
                        <Route path={'/logout'} element={<Logout />} />
                    </Routes>
                </Router>
            </UserContext.Provider>
        );
    }
};

export default App;
