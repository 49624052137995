import React, { useState } from 'react';


export const BUTTON = (props: any) => {
  return (
    <button
      type={props.type || "button"}
      className={`btn ${props.className || ""}`}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
    >
      {props.children}
    </button>
  );
}

export const SELECTOPTION = (props: any) => {
  return (
    <select
      className={`form-select form-control ${props.className || ""}`}
      value={props.value}
      onChange={props.onChange}
      about={props.about}
      style={props.style}
    >
      {props.children}
    </select>
  );
}
export const BLOCKBUTTON = (props: any) => {
  return (
    <button
      disabled={props.disabled}
      type={props.type || "button"}
      className={`btn w-100 ${props.className || ""}`}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </button>
  );
}

export const INPUT = (props: any) => {
  return (
    <input
      type={props.type || "text"}
      className={`form-control ${props.className || ""}`}
      placeholder={props.placeholder}
      value={props.value}
      pattern={props.pattern}
      onChange={props.onChange}
      id={props.id}
      readOnly={props.readOnly}
      required={props.required}
      disabled={props.disabled}
      ref={props.ref}
      name={props.name}
      maxLength={props.maxLength}
      autoComplete={props.autoComplete || "off"}
      style={props.style}
    />
  );
}



interface CheckboxContainerProps {
  label: string;
  initialChecked?: boolean;
  onChange?: (checked: boolean) => void;
  border?: boolean;
  className?: string;
}
export const CONTAINERCHECKBOX: React.FC<CheckboxContainerProps> = ({ label, initialChecked = false, onChange, border, className }) => {
  const [check, setCheck] = useState(initialChecked);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setCheck(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  const handleContainerClick = () => {
    setCheck(!check);
    if (onChange) {
      onChange(!check);
    }
  };
  const confirmBorder = ()=>{
    if(check && border){
      return 'pending checked-container '
    }else if (check && !border){
      return ' '
    }else{
      return 'bg-light '
    }
  }

  return (
    <div
      className={'d-flex align-items-center p-3 w3-round-large ' + confirmBorder() + className}
      onClick={handleContainerClick}
    >
      <input
        type="checkbox"
        name="accept_terms"
        id="accept_terms"
        className={'me-3 form-check-input d-block mt-0'}
        checked={check}
        onChange={handleCheckboxChange}
      />
      <p className='mb-0'>{label}</p>
    </div>
  );
};