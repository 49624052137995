import { gql } from '@apollo/client';


export const LOGIN = gql`
mutation Login($email: String, $token: String) {
  login(email: $email, token: $token) {
    accessToken
    refreshToken
  }
}
`;

export const LOGOUT = gql`
mutation Mutation {
  logout
}
`;

export const SEND_TOKEN = gql`
mutation SendLoginToken($email: String) {
  sendLoginToken(email: $email)
}
`;

export const REGISTER = gql`
mutation Register($fullname: String!, $email: String!, $phone: String!, $photo: String) {
  register(fullname: $fullname, email: $email, phone: $phone, photo: $photo) {
    accessToken
    refreshToken
  }
}
`;
export const VALIDATE_TOKEN = gql`
mutation ValidateToken($email: String, $token: String) {
  validateToken(email: $email, token: $token)
}
`;

export const UPDATE_ACCEPTED_TERMS = gql`
mutation UpdateTermsAccepted($email: String) {
  updateTermsAccepted(email: $email)
}
`;

export const UPDATE_PROFILE = gql`
mutation UpdateProfile($fullname: String, $photo: String, $phone: String) {
  updateProfile(fullname: $fullname, photo: $photo, phone: $phone) {
    id
    fullname
    email
    phone
    photo
    status
    role_name
    roles
    created_at
  }
}
`;

export const UPDATE_FIREBASE_TOKEN = gql`
mutation UpdateFirebaseToken($token: String) {
  updateFirebaseToken(token: $token)
}
`;

export const UPDATE_ORDER_LOCATION = gql`mutation UpdateOrderLocation($orderId: Int, $receiverName: String, $receiverAddress: String, $receiverPhone: String) {
  updateOrderLocation(order_id: $orderId, receiver_name: $receiverName, receiver_address: $receiverAddress, receiver_phone: $receiverPhone) {
    id
    order_code
    user_id
    package_name
    package_quantity
    pickup_address
    receiver_name
    receiver_address
    receiver_phone
    rider_id
    rider_name
    status
    amount
    cancellation_reason
    created_at
    delivered_at
  }
}`;

export const FUND_WALLET = gql`mutation FundWallet($amount: Float, $remarks: String) {
  fundWallet(amount: $amount, remarks: $remarks)
}`;

export const ADD_CARD = gql`
mutation AddCard($cardNumber: String, $cardName: String, $expiryDate: String, $cvv: String, $cardProvider: CardProvider) {
  addCard(card_number: $cardNumber, card_name: $cardName, expiry_date: $expiryDate, cvv: $cvv, card_provider: $cardProvider) {
    id
    card_number
    card_name
    expiry_date
    card_provider
    cvv
    created_at
  }
}
`;

export const CANCEL_ORDER = gql`
mutation CancelOrder($orderId: Int, $cancellationReason: String) {
  cancelOrder(order_id: $orderId, cancellation_reason: $cancellationReason)
}`;

export const VERIFY_COURIER_NIN = gql`
mutation VerifyCourierNIN($courierId: Int!, $nin: String) {
  verifyCourierNIN(courier_id: $courierId, nin: $nin)
}
`;
export const APPROVE_COURIER_NIN = gql`
mutation ApproveCourierNIN($approveCourierNinId: Int!) {
  approveCourierNIN(id: $approveCourierNinId)
}
`;
export const REJECT_COURIER_NIN = gql`
mutation RejectCourierNIN($rejectCourierNinId: Int!) {
  rejectCourierNIN(id: $rejectCourierNinId)
}
`;
export const APPROVE_COURIER_LICENSE = gql`
mutation ApproveCourierLicense($approveCourierLicenseId: Int!) {
  approveCourierLicense(id: $approveCourierLicenseId)
}
`;
export const REJECT_COURIER_LICENSE = gql`
mutation RejectCourierLicense($rejectCourierLicenseId: Int!) {
  rejectCourierLicense(id: $rejectCourierLicenseId)
}
`;
export const CREATE_ROLE = gql`
mutation CreateRole($name: String!, $json: JSON) {
  createRole(name: $name, json: $json) {
    id
    name
    json
    status
  }
}
`;
export const DELETE_ROLE = gql`
mutation DeleteRole($deleteRoleId: Int!) {
  deleteRole(id: $deleteRoleId)
}
`;
export const UPDATE_ROLE = gql`
mutation UpdateRole($updateRoleId: Int!, $name: String, $json: JSON) {
  updateRole(id: $updateRoleId, name: $name, json: $json) {
    id
    name
    json
    status
  }
}
`;

export const APPROVE_MERCHANT = gql`
mutation ApproveMerchant($approveMerchantId: Int!) {
  approveMerchant(id: $approveMerchantId) {
    id
    fullname
    email
    phone
    status
  }
}
`;
export const UPDATE_ADMIN_ACCOUNT = gql`
mutation UpdateAdminAccount($updateAdminAccountId: Int!, $fullname: String, $email: String, $phone: String, $status: String, $roleId: Int) {
  updateAdminAccount(id: $updateAdminAccountId, fullname: $fullname, email: $email, phone: $phone, status: $status, role_id: $roleId) {
    id
    fullname
    email
    phone
    photo
    status
    role_name
    roles
    created_at
  }
}
`;
export const CREATE_ADMIN_ACCOUNT = gql`
mutation CreateAdminAccount($fullname: String!, $email: String!, $phone: String!, $roleId: Int!, $status: String) {
  createAdminAccount(fullname: $fullname, email: $email, phone: $phone, role_id: $roleId, status: $status) {
    id
    fullname
    email
    phone
    photo
    status
    role_name
    roles
    created_at
  }
}
`;
export const DELETE_ADMIN_ACCOUNT = gql`
mutation DeleteAdminAccount($deleteAdminAccountId: Int!) {
  deleteAdminAccount(id: $deleteAdminAccountId)
}
`;
export const SEND_EMAIL_MESSAGE = gql`
mutation SendEmailMessage($to: String!, $subject: String!, $message: String!) {
  sendEmailMessage(to: $to, subject: $subject, message: $message)
}
`;
export const DELETE_EMAIL_MESSAGE = gql`
mutation DeleteEmailMessage($deleteEmailMessageId: Int!) {
  deleteEmailMessage(id: $deleteEmailMessageId)
}
`;
export const DELETE_ORDER = gql`
mutation DeleteOrder($deleteOrderId: Int!) {
  deleteOrder(id: $deleteOrderId)
}
`;
export const DELETE_MERCHANT = gql`
mutation DeleteMerchant($deleteMerchantId: Int!) {
  deleteMerchant(id: $deleteMerchantId)
}
`;
export const DELETE_COURIER = gql`
mutation DeleteCourier($deleteCourierId: Int!) {
  deleteCourier(id: $deleteCourierId)
}
`;
export const CREATE_TRANSACTION = gql`
mutation CreateTransaction($fullname: String, $date: String, $email: String, $amount: Float, $status: TransactionStatus, $remarks: String) {
  createTransaction(fullname: $fullname, _date: $date, email: $email, amount: $amount, status: $status, remarks: $remarks) {
    id
    fullname
    date
    email
    amount
    status
    remarks
    created_at
    code
  }
}
`;
export const UPDATE_ORDER_DETAILS = gql`
mutation UpdateOrderDetails($updateOrderDetailsId: Int!, $packageName: String, $senderName: String, $packageQuantity: Int, $receiverName: String, $receiverPhone: String, $pickupAddress: String, $receiverAddress: String, $amount: Float) {
  updateOrderDetails(id: $updateOrderDetailsId, package_name: $packageName, sender_name: $senderName, package_quantity: $packageQuantity, receiver_name: $receiverName, receiver_phone: $receiverPhone, pickup_address: $pickupAddress, receiver_address: $receiverAddress, amount: $amount) {
    id
    order_code
    package_name
    sender_name
    package_quantity
    receiver_name
    receiver_phone
    pickup_address
    receiver_address
    amount
    status
    cancellation_reason
    delivered_at
    created_at
  }
}
`;
export const EXPORT_COURIER = gql`
mutation ExportCourier($isHeaders: Boolean, $riderName: Int, $riderId: Int, $orderId: Int, $dateOfTransaction: Int, $merchantDetails: Int, $pickUpAt: Int, $deliveredAt: Int, $fileFormat: FileFormat, $riderIDs: [Int]) {
  exportCourier(isHeaders: $isHeaders, rider_name: $riderName, rider_id: $riderId, order_id: $orderId, date_of_transaction: $dateOfTransaction, merchant_details: $merchantDetails, pick_up_at: $pickUpAt, delivered_at: $deliveredAt, file_format: $fileFormat, riderIDs: $riderIDs)
}
`;
export const UPDATE_MERCHANT = gql`
mutation UpdateMerchant($updateMerchantId: Int!, $fullname: String, $email: String, $phone: String, $status: String, $walletBalance: Float) {
  updateMerchant(id: $updateMerchantId, fullname: $fullname, email: $email, phone: $phone, status: $status, wallet_balance: $walletBalance) {
    id
    fullname
    email
    phone
    status
  }
}
`;
export const DELETE_MERCHANT_NOTIFICATION = gql`
mutation DeleteMerchantNotification($deleteMerchantNotificationId: Int!) {
  deleteMerchantNotification(id: $deleteMerchantNotificationId)
}
`;
export const DELETE_COURIER_NOTIFICATION = gql`
mutation DeleteCourierNotification($deleteCourierNotificationId: Int!) {
  deleteCourierNotification(id: $deleteCourierNotificationId)
}
`;