import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import UserContext from '../Helpers/UserContext';
import Session from '../Helpers/Session';

const SidebarItem = ({ iconClassName, text, active = false, onClick }: { iconClassName: string; text: string; active?: boolean; onClick?: () => void }) => (
    <li className={`cursor-pointer sidebar-item mx-3 d-flex align-items-center ps-3 pe-4 py-1 mt-2 w3-round-large fw-bold ${active ? 'pending text-primary' : 'text-muted'}`} onClick={onClick}>
        <i className={"bi " + iconClassName + " fs-3"} />
        <span className='ms-2'>{text}</span>
    </li>
);

const Sidebar = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [expandMerchant, setExpandMerchant] = React.useState(false);
    const [expandCourier, setExpandCourier] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (path: string) => location.pathname === path;

    const pages = [
        { path: '/', icon: 'bi-house-door', text: 'Dashboard' },
        { path: '/merchant', icon: 'bi-people', text: 'Merchant', expand: expandMerchant, setExpand: setExpandMerchant, subItems: [{ path: '/merchant', text: 'All Merchants' },{ path: '/merchant/notification', text: 'Notifications' }, { path: '/merchant/manage'}] },
        { path: '/courier', icon: 'bi-truck', text: 'Courier', expand: expandCourier, setExpand: setExpandCourier, subItems: [{ path: '/courier', text: 'All Couriers' },{ path: '/courier/notification', text: 'Notifications' }, { path: '/courier/manage'},{path: "/courier/export"} ] },
        { path: '/order', icon: 'bi-box-seam', text: 'Order', subItems: [{ path: '/order/edit', text: "" }]  },
        { path: '/transaction', icon: 'bi-credit-card', text: 'Transaction', subItems: [{ path: '/transaction/add', text: "" }]  },
        { path: '/email', icon: 'bi-envelope', text: 'Email Messages', subItems: [{ path: '/email/compose', text: "" }]  },
        { path: '/audit-trails', icon: 'bi-file-earmark-text', text: 'Audit Trails' },
        { path: '/roles', icon: 'bi-person-check', text: 'Roles', subItems: [{ path: '/roles/add', text: "" },{ path: '/roles/edit', text: "" }] },
        { path: '/admin', icon: 'bi-gear', text: 'Admin Accounts', subItems: [{ path: '/admin/add', text: "" },{ path: '/admin/edit', text: "" }] },
        { path: '/reports', icon: 'bi-bar-chart', text: 'Reports' },
    ];

    return (
        <>
            <div className='d-flex align-items-start'>
                <div className="bg-light shadow-none sidebar h-100 border-end">
                    <div className=' border-bottom'>
                        <div className="d-flex align-items-center py-2">
                            <Link to={'/'} className="">
                                <img src="/images/gokari.png" alt="" height={"60px"} />
                            </Link>
                        </div>
                    </div>

                    <nav className="">
                        <ul className="list-group">
                            {pages.map((page) => {
                                if(user.roles === null) return null;
                                const hasReadPermission = user.roles.find((role: any) => role.page === page.text)?.roles.read === 1;
                                if (!hasReadPermission) return null;

                                return (
                                    <React.Fragment key={page.path}>
                                        <SidebarItem
                                            iconClassName={page.icon}
                                            text={page.text}
                                            active={isActive(page.path) || (page.subItems && page.subItems.some((subItem) => isActive(subItem.path)))}
                                            onClick={() => {
                                                navigate(page.path);
                                                if (page.setExpand) page.setExpand(!page.expand);
                                            }}
                                        />
                                        {page.subItems && (
                                            <ul className={'list-group ' + (page.expand ? 'd-block' : 'd-none')}>
                                                {page.subItems.map((subItem) => (
                                                    <li
                                                        key={subItem.path}
                                                        className={`cursor-pointer sidebar-item mx-3 d-flex align-items-center ps-5 pe-4 py-1 mt-2 w3-round-large fs-6 ${isActive(subItem.path) ? ' text-primary' : 'text-muted'}`}
                                                        onClick={() => navigate(subItem.path)}
                                                    >
                                                        {subItem?.text || ""}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
};
export default Sidebar;
